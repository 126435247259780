import React from "react"

interface ReavealPropsType {
  children: React.ReactNode,
  delay?: number,
}
export const RevealSlideUp = ({ children, delay = 300 }: ReavealPropsType) => {
  return (
    <div data-sal="slide-up" data-sal-delay={delay} data-sal-easing="ease">
      {children}
    </div>
  )
}
export const RevealSlideLeft = ({ children, delay = 300 }: ReavealPropsType) => {
  return (
    <div data-sal="slide-left" data-sal-delay={delay} data-sal-easing="ease">
      {children}
    </div>
  )
}
export const RevealSlideRight = ({ children, delay = 300 }: ReavealPropsType) => {
  return (
    <div data-sal="slide-right" data-sal-delay={delay} data-sal-easing="ease">
      {children}
    </div>
  )
}
export const RevealSlideDown = ({ children, delay = 300 }: ReavealPropsType) => {
  return (
    <div data-sal="slide-down" data-sal-delay={delay} data-sal-easing="ease">
      {children}
    </div>
  )
}

export const RevealFlipUp = ({ children, delay = 300 }: ReavealPropsType) => {
  return (
    <div data-sal="flip-up" data-sal-delay={delay} data-sal-easing="ease">
      {children}
    </div>
  )
}
export const RevealFlipRight = ({ children, delay = 300 }: ReavealPropsType) => {
  return (
    <div data-sal="flip-right" data-sal-delay={delay} data-sal-easing="ease">
      {children}
    </div>
  )
}
export const RevealFade = ({ children, delay = 300 }: ReavealPropsType) => {
  return (
    <div data-sal="fade" data-sal-delay={delay} data-sal-easing="ease">
      {children}
    </div>
  )
}
