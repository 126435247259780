import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  RevealSlideUp,
  RevealSlideRight,
  RevealFlipUp,
  RevealFlipRight,
  RevealFade,
} from "../utils/reveal"


export type PriceItemTitles = string[]

export type PriceItemObjects = {
  item: string,
  included: boolean
}

const PricingPage = () => {
  const FORM_ENDPOINT = "/contact/#"
  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true)
    }, 100)
  }

  const cardPriceOuterDiv =
    "max-w-sm mx-auto border rounded-lg md:mx-2 lg:mx-4 dark:border-gray-700 mb-4 md:mb-0 bg-gradient-to-b from-black to-gray-800"

  const pricingIncludedItems: PriceItemTitles = [
    "Custom Lottie animation",
    "Animated section content",
    "Optimized images",
    "Page Navigation",
    "Firebase Database integration",
    "Firebase Auth integration",
  ]

  const pricingIncludedItems_tier1: PriceItemObjects[] = [
    {
      item: pricingIncludedItems[0],
      included: true,
    },
    {
      item: pricingIncludedItems[1],
      included: true,
    },
    {
      item: pricingIncludedItems[2],
      included: true,
    },
    {
      item: pricingIncludedItems[3],
      included: false,
    },
    {
      item: pricingIncludedItems[4],
      included: false,
    },
    {
      item: pricingIncludedItems[5],
      included: false,
    },
  ]
  const pricingIncludedItems_tier2 = [
    {
      item: pricingIncludedItems[0],
      included: true,
    },
    {
      item: pricingIncludedItems[1],
      included: true,
    },
    {
      item: pricingIncludedItems[2],
      included: true,
    },
    {
      item: pricingIncludedItems[3],
      included: true,
    },
    {
      item: pricingIncludedItems[4],
      included: true,
    },
    {
      item: pricingIncludedItems[5],
      included: false,
    },
  ]
  const pricingIncludedItems_tier3 = [
    {
      item: pricingIncludedItems[0],
      included: true,
    },
    {
      item: pricingIncludedItems[1],
      included: true,
    },
    {
      item: pricingIncludedItems[2],
      included: true,
    },
    {
      item: pricingIncludedItems[3],
      included: true,
    },
    {
      item: pricingIncludedItems[4],
      included: true,
    },
    {
      item: pricingIncludedItems[5],
      included: true,
    },
  ]

  return (
    <Layout>
      <SEO
        keywords={[
          `pricing`,
          `web application`,
          `website`,
          `lottie`,
          `animation`,
          `reactjs`,
          `gatsbyjs`,
          `nextjs`,
          `tailwind`,
          `tailwindcss`,
        ]}
        title="Pricing"
      />

      <section className="max-w-screen-lg m-auto mt-20 mb-5">
        <h1 className="text-center p-3 mt-5 mb-4 text-2xl font-bold text-white">
          Engaging modern web applications with custom animation.
        </h1>
      </section>
      <section className="max-w-screen-lg m-auto mb-10">
        <h2 className="text-center p-3 mb-4 text-lg md:text-base text-white font-family-opensans">
          Choose from the plan that best suits your needs. All plans included a
          custom integrated animation and a revision.
        </h2>
      </section>
      <section className="w-full mb-10">
        <div className="xl:items-center xl:-mx-8 xl:flex">
          <div className="flex-1 xl:mx-8">
            <div className="max-w-screen-xl m-auto grid grid-cols-1 gap-1 md:grid-cols-3 mb-2 md:mb-0">
              <div className={`${cardPriceOuterDiv}`}>
                <div className="p-6">
                  <h2 className="text-xl font-medium text-gray-700 capitalize lg:text-3xl dark:text-white">
                    Basic
                  </h2>
                  <p className="mt-4 text-gray-500 dark:text-gray-300">
                    Responsive single page ReactJS web application with custom
                    Lottie animation.
                  </p>

                  <h2 className="mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300">
                    $250
                  </h2>

                  <div className="w-full flex-1 flex-row">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="text-gray-500 float-left mr-4"
                      fill="currentColor"
                    >
                      <path d="M5 18c4.667 4.667 12 1.833 12-4.042h-3l5-6 5 6h-3c-1.125 7.98-11.594 11.104-16 4.042zm14-11.984c-4.667-4.667-12-1.834-12 4.041h3l-5 6-5-6h3c1.125-7.979 11.594-11.104 16-4.041z" />
                    </svg>
                    <p className=" mt-1 text-gray-500 dark:text-gray-300">
                      2 revisions
                    </p>
                  </div>

                  <div className="w-full flex-1 flex-row  mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="text-gray-500 float-left mr-4"
                      fill="currentColor"
                    >
                      <path d="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24-4v20h2v-18h18v-2h-20z" />
                    </svg>
                    <p className=" mt-1 text-gray-500 dark:text-gray-300">
                      1 page
                    </p>
                  </div>

                  <button className="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                    Order
                  </button>
                </div>

                <hr className="border-gray-500 dark:border-gray-700" />

                <div className="p-6">
                  <h2 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">
                    What's included:
                  </h2>

                  <div className="mt-1 md:mt-8 space-y-1 md:space-y-4 text-xs sm:text-sm lg:text-lg">
                    <PricingIncludedItem
                      pricingIncludedItems={pricingIncludedItems_tier1}
                    />
                  </div>
                </div>
              </div>

              <div className={`${cardPriceOuterDiv} bg-gray-100`}>
                <div className="p-6">
                  <h2 className="text-xl font-medium text-gray-700 capitalize lg:text-3xl dark:text-white">
                    Standard
                  </h2>

                  <p className="mt-4 text-gray-500 dark:text-gray-300">
                    Responsive small ReactJS website with 2 custom Lottie
                    animations.
                  </p>

                  <h2 className="mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300">
                    $750
                  </h2>

                  <div className="w-full flex-1 flex-row">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="text-gray-500 float-left mr-4"
                      fill="currentColor"
                    >
                      <path d="M5 18c4.667 4.667 12 1.833 12-4.042h-3l5-6 5 6h-3c-1.125 7.98-11.594 11.104-16 4.042zm14-11.984c-4.667-4.667-12-1.834-12 4.041h3l-5 6-5-6h3c1.125-7.979 11.594-11.104 16-4.041z" />
                    </svg>
                    <p className=" mt-1 text-gray-500 dark:text-gray-300">
                      2 revisions
                    </p>
                  </div>

                  <div className="w-full flex-1 flex-row  mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="text-gray-500 float-left mr-4"
                      fill="currentColor"
                    >
                      <path d="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24-4v20h2v-18h18v-2h-20z" />
                    </svg>
                    <p className=" mt-1 text-gray-500 dark:text-gray-300">
                      3 pages
                    </p>
                  </div>

                  <button className="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                    Order
                  </button>
                </div>

                <hr className="border-gray-200 dark:border-gray-700" />

                <div className="p-6">
                  <h2 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">
                    What's included:
                  </h2>

                  <div className="mt-1 md:mt-8 space-y-1 md:space-y-4 text-xs sm:text-sm lg:text-lg">
                    <PricingIncludedItem
                      pricingIncludedItems={pricingIncludedItems_tier2}
                    />
                  </div>
                </div>
              </div>

              <div className={`${cardPriceOuterDiv} bg-gray-100`}>
                <div className="p-6">
                  <h2 className="text-xl font-medium text-gray-700 capitalize lg:text-3xl dark:text-white">
                    Premium
                  </h2>

                  <p className="mt-4 text-gray-500 dark:text-gray-300">
                    Responsive large ReactJS website with 3 custom Lottie
                    animations.
                  </p>

                  <h2 className="mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300">
                    $1500
                  </h2>

                  <div className="w-full flex-1 flex-row">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="text-gray-500 float-left mr-4"
                      fill="currentColor"
                    >
                      <path d="M5 18c4.667 4.667 12 1.833 12-4.042h-3l5-6 5 6h-3c-1.125 7.98-11.594 11.104-16 4.042zm14-11.984c-4.667-4.667-12-1.834-12 4.041h3l-5 6-5-6h3c1.125-7.979 11.594-11.104 16-4.041z" />
                    </svg>
                    <p className=" mt-1 text-gray-500 dark:text-gray-300">
                      3 revisions
                    </p>
                  </div>

                  <div className="w-full flex-1 flex-row  mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="text-gray-500 float-left mr-4"
                      fill="currentColor"
                    >
                      <path d="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24-4v20h2v-18h18v-2h-20z" />
                    </svg>
                    <p className=" mt-1 text-gray-500 dark:text-gray-300">
                      10 pages
                    </p>
                  </div>

                  <button className="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                    Order
                  </button>
                </div>

                <hr className="border-gray-200 dark:border-gray-700" />

                <div className="p-6">
                  <h2 className="text-lg font-medium text-gray-700 capitalize lg:text-xl dark:text-white">
                    What's included:
                  </h2>

                  <div className="mt-1 md:mt-8 space-y-1 md:space-y-4 text-xs sm:text-sm lg:text-lg">
                    <PricingIncludedItem
                      pricingIncludedItems={pricingIncludedItems_tier3}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  PRODUCT OVERVIEW */}
      <section id="services" className="bg-gray-900 dark:bg-gray-900 pt-5">
        <div className="container px-6 py-10 mx-auto">
          <RevealSlideUp>
            <h2 className="text-3xl font-semibold text-center text-gray-800  font-family-opensans  capitalize lg:text-4xl dark:text-white">
              Services
            </h2>
          </RevealSlideUp>
          <div className="container px-6 py-12 mx-auto"></div>
        </div>
      </section>
    </Layout>
  )
}

export default PricingPage

const PricingIncludedItem = ({ _pricingIncludedItems }: any) => {
  const pricingIncludedItems: PriceItemObjects[] = _pricingIncludedItems as PriceItemObjects[]
  const iconIncluded = (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      className="w-10 h-10 pr-2 text-green-500"
      fill="currentColor"
      strokeMiterlimit="2"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm0 1.5c-4.69 0-8.498 3.807-8.498 8.497s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.497-8.497-8.497zm-5.049 8.886 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z"
        fillRule="nonzero"
      />
    </svg>
  )
  const iconNotIncluded = (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      className="w-10 h-10 pr-2 text-red-500"
      fill="currentColor"
      strokeMiterlimit="2"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
        fillRule="nonzero"
      />
    </svg>
  )
  return (
    <div className="flex flex-row flex-wrap w-full">
      {pricingIncludedItems && pricingIncludedItems.length > 0 && pricingIncludedItems.map((pricingIncludedItem: PriceItemObjects, index: number) => (
        <div key={`_${index}`} className="flex items-center w-full">
          <div className="w-5 md:w-10">
            {pricingIncludedItem.included ? iconIncluded : iconNotIncluded}
          </div>
          <p className="mx-4 text-gray-400 dark:text-gray-800">
            {pricingIncludedItem.item}
          </p>
        </div>
      ))}
    </div>
  )
}
